/*
  Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : ionic 5 groceryee app
  Created : 10-Sep-2020
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2020-present initappz.
*/
import { Injectable } from '@angular/core';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public PAYMENT_METHOD_ENVIRONMENTS = {
    PRODUCTION: 'PROD',
    DEVELOPMENT: 'DEV'
  }

  public PAYMENT_METHOD_STATUS = {
    ENABLED: 'ENABLED',
    DISABLED: 'DISABLED'
  }

  public cart: any[] = [];
  public itemId: any[] = [];
  public totalPrice: any = 0;
  public grandTotal: any = 0;
  public coupon: any;
  public discount: any = 0;
  public orderTax: any = 0;
  public orderPrice: any;
  public shipping: any;
  public shippingPrice: any = 0;
  public minOrderPrice: any = 0;
  public freeShipping: any = 0;
  public datetime: any;
  public deliveryAt: any;
  public deliveryAddress: any;
  public deliveryPrice: any = 0;
  public post_cost_apply_price: any = 0;
  public stores: any[] = [];


  public paymentMethodsAvailable: boolean;
  public haveCOD: boolean;
  public havePayPal: boolean;
  public haveMollie: boolean;
  
  public userOrderTaxByStores: any[] = [];
  constructor(
    public util: UtilService
  ) {
    const data = localStorage.getItem('cart');
    if (data && data !== null && data !== 'null') {
      const userCart = JSON.parse(data);
      if (userCart && userCart.length > 0) {
        this.cart = userCart;
        this.itemId = [...new Set(this.cart.map(item => item.id))];
        this.calcuate();
      } else {
        this.calcuate();
      }
    } else {
      this.calcuate();
    }

  }



  clearCart() {
    this.cart = [];
    this.itemId = [];
    this.totalPrice = 0;
    this.grandTotal = 0;
    this.coupon = undefined;
    this.discount = 0;
    this.orderPrice = 0;
    this.datetime = undefined;
    this.stores = [];
    this.deliveryAddress = null;
    this.shippingPrice = 0;
    this.deliveryPrice = 0;
    this.util.clearKeys('cart');
  }

  addItem(item) {
    // console.log('item to adde', item);
    this.cart.push(item);
    this.itemId.push(item.id);
    this.calcuate();
  }

  addQuantity(quantity, id) {
    // console.log('iddd-->>', id);
    // console.log('quantity', quantity);
    this.cart.forEach(element => {
      if (element.id === id) {
        element.quantiy = quantity;
      }
    });
    this.calcuate();
  }

  removeItem(id) {
    // console.log('remove this item from cart');
    // console.log('current cart items', this.cart);
    this.cart = this.cart.filter(x => x.id !== id);
    this.itemId = this.itemId.filter(x => x !== id);

    // console.log('====>>>>>>>>>', this.cart);
    // console.log('items====>>>', this.itemId);
    if (this.cart.length) {
      this.calcuate();
    } else {
      this.clearCart();
    }
  }

  calcuate() {
    // console.log('cart==>', this.cart);
    // console.log('couiponnnnnnneee---->>', this.coupon);
    this.userOrderTaxByStores = [];
    let total = 0;
    this.cart.forEach(element => {
      if (element && element.discount === '0') {
        if (element.size === '1' || element.size === 1) {
          if (element.variations[0].items[element.variant].discount && element.variations[0].items[element.variant].discount !== 0) {
            total = total + (parseFloat(element.variations[0].items[element.variant].discount) * element.quantiy);
          } else {
            total = total + (parseFloat(element.variations[0].items[element.variant].price) * element.quantiy);
          }
        } else {
          total = total + (parseFloat(element.original_price) * element.quantiy);
        }
      } else {
        if (element.size === '1' || element.size === 1) {
          if (element.variations[0].items[element.variant].discount && element.variations[0].items[element.variant].discount !== 0) {
            total = total + (parseFloat(element.variations[0].items[element.variant].discount) * element.quantiy);
          } else {
            total = total + (parseFloat(element.variations[0].items[element.variant].price) * element.quantiy);
          }
        } else {
          total = total + (parseFloat(element.sell_price) * element.quantiy);
        }
      }
    });
    // console.log('total->', total);
    this.totalPrice = total.toFixed(2);

    if (this.coupon && this.coupon.type) {
      const min = parseFloat(this.coupon.min);
      if (this.totalPrice >= min) {
        if (this.coupon && this.coupon.type === 'per') {
          // console.log('per');
          function percentage(num, per) {
            return (num / 100) * per;
          }
          const totalPrice = percentage(parseFloat(this.totalPrice).toFixed(2), parseFloat(this.coupon.off));
          // console.log('============>>>>>>>>>>>>>>>', totalPrice);
          this.discount = totalPrice.toFixed(2);
          // this.grandTotal = (this.totalPrice - parseFloat(this.discount)) + this.orderTax;
        } else {
          // console.log('currency');
          this.discount = this.coupon.off;
          // this.grandTotal = (this.totalPrice - parseFloat(this.discount)) + this.orderTax;
        }
      } else {
        this.discount = 0;
        this.coupon = null;
      }
    } else {
      //this.grandTotal = this.totalPrice + this.orderTax;
      this.grandTotal = this.totalPrice;
    }
    if (this.stores && this.stores.length && this.deliveryAddress && this.deliveryAt === 'home') {
      // console.log('--->>> delivery address===>>>', this.deliveryAddress);
      // this.deliveryPrice = 0;
      let totalKM = 0;
      let taxEach = 0;
      this.stores.forEach(async (element) => {
        // console.log('This Is Sho Details:<<<<<<<<<<<<<<< ', element);
        const distance = await this.distanceInKmBetweenEarthCoordinates(this.deliveryAddress.lat, this.deliveryAddress.lng,
          element.lat, element.lng);
        // console.log('distance', distance, element);
        totalKM = totalKM + distance;
        // console.log('total->', element.name, totalKM * this.shippingPrice);
        // const storeCount = this.stores.length + 1;
        taxEach = this.orderTax / this.stores.length;
        const extraChargeParam = {
          store_id: element.uid,
          distance: distance.toFixed(2),
          tax: taxEach.toFixed(2),
          shipping: this.shipping,
          shippingPrice: this.shippingPrice
        };
        // console.log(extraChargeParam);
        this.userOrderTaxByStores.push(extraChargeParam);

        //if (this.freeShipping > this.totalPrice) {
        if (element.delivery_area !== 'everywhere') {
          // console.log('totalKM > element.delivery_area',totalKM ,element.delivery_area);
          if (totalKM > element.delivery_area) {
            this.util.calculateShopChange('cant_add');
          } else {
            if (element.delivery_charges_type === 'Fixed Price') {
              var IndexPrice: any = Math.abs(element.delivery_charge);
            } else {
              var priceArr = JSON.parse(element.delivery_charge);
              var IndexPrice: any = this.getValIndex(totalKM, priceArr);
            }

            const distancePricer = IndexPrice;
            // console.log('distancePricer:', distancePricer);
            // console.log('====================> KM price', Math.abs(distancePricer));
            this.deliveryPrice = distancePricer.toFixed(2);
            if (!this.discount || this.discount === null) {
              this.discount = 0;
            }
            //this.grandTotal = (this.totalPrice - parseFloat(this.discount)) + this.orderTax + distancePricer;
            this.grandTotal = (this.totalPrice - parseFloat(this.discount)) + distancePricer;
            this.grandTotal = parseFloat(this.grandTotal).toFixed(2);
            // console.log('grand total===>>', this.grandTotal);
            this.util.calculateShopChange('can_add');
            // console.log('deliveryeeeeeeeee', this.deliveryPrice);
          }
        } else {
          this.deliveryPrice = Math.abs(element.delivery_charge);
          // console.log('shippppppppp=-======0-00-=-=', this.shippingPrice);
          if (!this.discount || this.discount === null) {
            this.discount = 0;
          }
          //this.grandTotal = (this.totalPrice - parseFloat(this.discount)) + this.orderTax + this.shippingPrice;
          this.grandTotal = (this.totalPrice - parseFloat(this.discount)) + this.shippingPrice;
          this.grandTotal = parseFloat(this.grandTotal).toFixed(2);
          // console.log('grand total===>>', this.grandTotal);
          this.util.calculateShopChange('can_add');
        }

        // } else {
        //   this.deliveryPrice = 0;
        //   // console.log('discount', this.discount);
        //   if (!this.discount || this.discount === null) {
        //     this.discount = 0;
        //   }
        //   // console.log('order tax', this.orderTax);
        //   this.grandTotal = (this.totalPrice - parseFloat(this.discount)) + this.orderTax;
        //   this.grandTotal = parseFloat(this.grandTotal).toFixed(2);
        //   // console.log('grand total===>>', this.grandTotal);
        // }
      });

      //finish of the DIlivery chages
    } else {
      // console.log('no store,no delivery address, no shipping price valid');
      let taxEach = 0;
      this.stores.forEach(async (element) => {
        taxEach = this.orderTax / this.stores.length;
        const extraChargeParam = {
          store_id: element.uid,
          distance: 0,
          tax: taxEach.toFixed(2),
          shipping: this.shipping,
          shippingPrice: this.shippingPrice
        };
        // console.log(extraChargeParam);
        this.userOrderTaxByStores.push(extraChargeParam);
      });
      
      // console.log('extra delivery paarammmmm----<<<', this.userOrderTaxByStores);
      if(this.deliveryAt === 'post'){
        //var grandTotalFix = (this.totalPrice - parseFloat(this.discount)) + parseFloat(this.orderTax);
        var grandTotalFix = (this.totalPrice - parseFloat(this.discount));
        if(this.post_cost_apply_price < grandTotalFix){
          this.deliveryPrice = 0;
        }
      }else{
        this.deliveryPrice = 0;
      }
      this.discount = this.discount === null || this.discount === 0 || !this.discount ? 0 : this.discount;
      //this.grandTotal = (this.totalPrice - parseFloat(this.discount)) + parseFloat(this.orderTax) + this.deliveryPrice;
      this.grandTotal = (this.totalPrice - parseFloat(this.discount)) + this.deliveryPrice;
      this.grandTotal = parseFloat(this.grandTotal).toFixed(2);
      // console.log('grand total===>>', this.grandTotal);
    }

    // console.log('=====>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', this.orderTax);
    this.util.clearKeys('cart');
    this.util.setKeys('cart', JSON.stringify(this.cart));
    // this.createBulkOrder();
  }

  checkProductInCart(id) {
    return this.itemId.includes(id);
  }

  degreesToRadians(degrees) {
    return degrees * Math.PI / 180;
  }

  distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
    // console.log(lat1, lon1, lat2, lon2);
    const earthRadiusKm = 6371;

    const dLat = this.degreesToRadians(lat2 - lat1);
    const dLon = this.degreesToRadians(lon2 - lon1);

    lat1 = this.degreesToRadians(lat1);
    lat2 = this.degreesToRadians(lat2);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadiusKm * c;
  }

  /**
   * Added by Pium 
   * This function will return existing product's store id.
   * Implemented when adding feature that cart only can contain products from a single store
   * @returns store id of existing products, if cart has no items then return 'EMPTY_CART'
   */
  getStoreIdOfExistingProductsInCart(): string {
    if (this.cart && this.cart.length) {
      return this.cart[0].store_id;
    }
    return 'EMPTY_CART';
  }

  /**
   * Added by Pium
   * Will show a confirmation dialog when user try to add a product from another store other than existing product's store in cart
   * @param Swal send Swal reference
   * @returns Promise ('CONFIRMED' or 'CANCELED')
   */
  cartItemsClearConfirmation(Swal) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: this.util.getString('Are you sure?'),
        text: this.util.getString('Existing items in your cart are from another store. If you add this item into cart, existing items in your cart will be cleared!'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7fad39',
        cancelButtonColor: '#d33',
        confirmButtonText: this.util.getString('Add to cart'),
        cancelButtonText: this.util.getString('Cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          return resolve('CONFIRMED');
        } else {
          return resolve('CANCELED');
        }
      });
    });
  }

  getValIndex(totalKM, priceArr) {
    var Price;
    for (let index = 0; index < priceArr.length; index++) {
      if (index == totalKM) {
        Price = priceArr[index];
      }
      if ((index < totalKM && totalKM < index + 1)) {
        Price = priceArr[index];
      }
      if (totalKM == index + 1) {
        Price = priceArr[index];
      }
    }
    return Price;
  }
}
